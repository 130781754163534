<template>
  <div class="box-wrap">
    <div class="banner-box">
      <van-swipe
        @change="onChangeSwipe"
        ref="my-swiper"
        class="my-swiper"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item
          v-for="(sitem, index) of pageData.headImages"
          :key="index"
        >
          <img class="swipe-img" :src="sitem" alt="" />
          <div v-show="pageData.videoUrl">
            <img
              @click="playVideo"
              class="play-icon"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/play-icon_409018134985654287.png"
              alt=""
            />
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="swipe-indicators" v-show="pageData.headImages && pageData.headImages.length > 1">
        <div
          class="indicator-item"
          @click="goSlide(index)"
          :class="{ indicatorActive: index === current }"
          v-for="(item, index) of pageData.headImages"
          :key="index"
        ></div>
      </div>
    </div>
    <div class="top-box">
      <h3 class="top-title">{{ pageData.cemeteryName }}</h3>
      <div class="tags">
        <div
          class="tag-item"
          v-for="(tag, index) of pageData.tag"
          :key="index + tag"
        >
          {{ tag }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-list">
          <div class="info-label">价 格：</div>
          <div class="info-value">
            <p>
              <span>¥</span><b>{{ pageData.price | setPrice }}</b
              ><span>万 起</span>
            </p>
          </div>
        </div>
        <div class="info-list">
          <div class="info-label">电 话：</div>
          <div class="info-value">
            <h4>
              <a @click="telClick" href="tel:4008122133"
                ><span>4008-122-133</span> <img src="@/assets/img/details/phone.png" alt=""
              /></a>
            </h4>
          </div>
        </div>
        <div class="info-list">
          <div class="info-label">地 址：</div>
          <div class="info-value">
            <h5>{{ pageData | setAddress }}</h5>
          </div>
        </div>
        <div class="info-list">
          <div class="info-label">亮 点：</div>
          <div class="info-value">
            <h5>{{ pageData.description }}</h5>
          </div>
        </div>
      </div>
      <div class="top-btn-item">
        <div class="btn-left">
          <div class="btn-icon">来园参观, 需提前登记</div>
          <div class="btn-item" @click="showForm">立即预约看墓</div>
        </div>
        <div class="btn-right">
          <img class="code-img" src="@/assets/img/details/qr-code.png" alt="" />
        </div>
      </div>
      <!-- <div class="note-item">今日已有<span>13</span>人预约参观</div> -->
      <div class="fl-box">
        <h3 class="fl-title">线上预约专享福利</h3>
        <div class="fl-item">
          <div class="fl-list" @click="goPage('two')"></div>
          <div class="fl-list" @click="goPage('three')"></div>
          <div class="fl-list" @click="goPage('one')">
            <div class="list-left">
              <div class="left-top">
                <span>¥</span>
                <p>200</p>
              </div>
              <div class="left-bottom">车油补，适合<span>自驾参观</span></div>
            </div>
            <div class="fl-right">现金</div>
          </div>
          <div class="fl-list" @click="goPage('one')">
            <div class="list-left">
              <div class="left-top">
                <span>¥</span>
                <p>2000</p>
              </div>
              <div class="left-bottom">直接抵扣购买金额</div>
            </div>
            <div class="fl-right">立减金</div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-box">
      <div class="list-item">
        <div class="list-title" v-if="pageData.cemeteryIntroduce">
          <span class="icon-css"></span>
          <h3>园区介绍</h3>
        </div>
        <div class="list-text">{{ pageData.cemeteryIntroduce }}</div>
      </div>
      <div class="list-item">
        <div class="list-title" v-if="pageData.environmentImages">
          <span class="icon-css"></span>
          <h3>园区环境</h3>
        </div>
        <div class="list-img">
          <img
            v-for="(item, index) of pageData.environmentImages"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
      </div>
      <div class="list-item">
        <div class="list-title" v-if="pageData.introduceImages">
          <span class="icon-css"></span>
          <h3>碑型介绍</h3>
        </div>
        <div class="list-img">
          <img
            v-for="(item, index) of pageData.introduceImages"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
      </div>
      <div class="list-item" v-show="pageData.introduceImages && isShowMap">
        <div class="list-title">
          <span class="icon-css"></span>
          <h3>园区位置</h3>
        </div>
        <div class="iframe-box">
          <div id="container" class="amap-container"></div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="lc-content">
        <h3 class="title-text">购墓流程</h3>
        <div class="lc-img-list">
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-1_409016640756465749.png"
              alt=""
            />
            <p>预约咨询</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-2_409016640777437192.png"
              alt=""
            />
            <p>专车接送</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-3_409016640794214499.png"
              alt=""
            />
            <p>现场勘选</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-4_409016921107939328.png"
              alt=""
            />
            <p>合同签署</p>
          </div>
        </div>
        <div class="lc-img-list last">
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-5_409016921103745078.png"
              alt=""
            />
            <p>墓碑制作</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-6_409016921116327939.png"
              alt=""
            />
            <p>办理安葬</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-7_409016921250545743.png"
              alt=""
            />
            <p>售后服务</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wt-box">
      <h3 class="title-css">常见问题</h3>
      <div class="wt-list">
        <div class="wt-item">
          <img class="wt-icon" src="@/assets/img/homerec/wenhao.png" alt="" />
          <p class="wt-text">购买墓地，除了购墓费用外，还有哪些费用？</p>
          <div class="zx-btn" @click="goKf">
            <img class="zx-icon" src="@/assets/img/homerec/wx-zx.png" />
            <span>立即咨询</span>
          </div>
        </div>
        <div class="wt-item">
          <img class="wt-icon" src="@/assets/img/homerec/wenhao.png" alt="" />
          <p class="wt-text">北京市哪些陵园是民政局批复的合法陵园？</p>
          <div class="zx-btn" @click="goKf">
            <img class="zx-icon" src="@/assets/img/homerec/wx-zx.png" />
            <span>立即咨询</span>
          </div>
        </div>
        <div class="wt-item">
          <img class="wt-icon" src="@/assets/img/homerec/wenhao.png" alt="" />
          <p class="wt-text">墓地是否有产权和期限，期限到期后怎么办？</p>
          <div class="zx-btn" @click="goKf">
            <img class="zx-icon" src="@/assets/img/homerec/wx-zx.png" />
            <span>立即咨询</span>
          </div>
        </div>
      </div>
    </div>
    <div class="b-a-box">
      <a class="beian" href="https://beian.miit.gov.cn/#/home">蜀ICP备2021003234号</a>
      <p @click="goHome">成都众兴未来科技有限公司<span>公司简介</span></p>
    </div>
    <div class="kf-box">
      <div class="kf-btn" @click="goKf">在线咨询</div>
    </div>
    <div class="fixed-box">
      <div class="home-btn-css" @click="goHomeRec">
        <img src="@/assets/img/details/home-icon.png" alt="" />
        <span>首页</span>
      </div>
      <div class="btns">
        <a @click="telClick" href="tel:4008122133" class="fiexd-btn">
          <img src="@/assets/img/homerec/phone.png" alt="" />
          <div class="btn-item">
            <p>电话咨询</p>
            <span>4008 122 133</span>
          </div>
        </a>
        <div class="fiexd-btn" @click="showForm">立即预约看墓</div>
      </div>
    </div>
    <!-- 视频播放 -->
    <van-overlay z-index="2" :show="showVideo" @click="hideVideoFun" />
    <div class="videoModal" v-show="showVideo">
      <div class="videoModalContent">
        <video
          class="videoCss"
          id="video"
          :src="pageData.videoUrl"
          controls="true"
          preload="auto"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
          style="object-fit: fill"
        ></video>
      </div>
      <van-icon name="close" class="videoModalClose" @click="hideVideoFun" />
    </div>
    <van-popup
      v-model="showModal"
      safe-area-inset-bottom
      position="bottom"
      closeable
    >
      <div class="form-wrap">
        <FormModal :startTime="startTime" :code="pageCode" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getCDetailApi, collectPointApi } from "@/api/apiList.js";
import { mapGetters } from "vuex";
import { setChannel } from "@/util/setChannel.js";
export default {
  name: "cemeteryDetail",
  filters: {
    setPrice(p) {
      let str = (p / 1000000 + "").split(".");
      if (str.length > 1) {
        str = str[0] + "." + str[1].slice(0, 2);
      } else {
        str = str[0] || 0 + ".00";
      }
      return str;
    },
    setAddress(data) {
      let str = "";
      if (
        data.cityCode == "1101" ||
        data.cityCode == "3101" ||
        data.cityCode == "5001" ||
        data.cityCode == "1201"
      ) {
        str = data.provinceName;
      } else {
        str = data.cityName;
      }
      str += data.addressDetail;
      return str;
    },
  },
  components: {
    FormModal: () => import("@/views/homerec/form.vue"),
  },
  data() {
    return {
      pageCode: "",
      pageTitle: "",
      pageData: {},
      current: 0,
      showVideo: false,
      map: null,
      marker: null,
      isShowMap: true,
      showModal: false,
      startTime: 0,
    };
  },
  async activated() {
    setChannel(this)
    this.startTime = +new Date();
    let toast = this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    let { title, code } = this.$route.query;
    code ? (this.pageCode = code) : "";
    title ? (this.pageTitle = title) : "";
    await this.getCDetail();
    toast.clear();
    this.initMap();
    if (!title) {
      document.title = this.pageData.cemeteryName || "如山选墓";
    }
  },
 created(){
    setTimeout(()=> {
     this.buryingPage(6);
    },1000)
  },
  computed: {
    swiper() {
      return this.$refs["my-swiper"];
    },
    ...mapGetters(["pages", "UUID"]),
  },
  methods: {
    getCDetail() {
      return new Promise((resolve) => {
        getCDetailApi({
          code: this.pageCode,
        })
          .then((res) => {
            this.pageData = res.data.data;
            console.log("请求成功:", res.data.data);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    onChangeSwipe(index) {
      this.current = index;
    },
    goSlide(index) {
      this.swiper.swipeTo(index);
    },
    playVideo() {
      this.buryingPage(4);
      this.showVideo = true;
      document.getElementById("video").play();
    },
    hideVideoFun() {
      document.getElementById("video").pause();
      this.showVideo = false;
    },
    //
    initMap() {
      let AMap = window.AMap;
      let positionN = this.pageData.cemeteryPosition.split(",");
      if (!positionN[0] || !positionN[1]) {
        this.isShowMap = false;
        return;
      }
      this.isShowMap = true;
      var position = new AMap.LngLat(positionN[0], positionN[1]);

      // 创建地图实例
      var map = new AMap.Map("container", {
        zoom: 13,
        center: position,
        resizeEnable: true,
      });

      // 点标记显示内容，HTML要素字符串
      var markerContent =
        "" +
        '<div class="custom-content-marker">' +
        '   <img style="width: 30px" src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E5%AE%9A%E4%BD%8D_468389463383556188.png">' +
        "</div>";

      var marker = new AMap.Marker({
        position: position,
        // 将 html 传给 content
        content: markerContent,
        // 以 icon 的 [center bottom] 为原点
        offset: new AMap.Pixel(-13, -30),
      });

      // 将 markers 添加到地图
      map.add(marker);
      this.map = map;
      this.marker = marker;
    },

    // 清除 marker
    clearMarker() {
      this.map.remove(this.marker);
    },
    goKf() {
      this.buryingPage(2);
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc51653415422b34fa";
    },
    goHome() {
      this.$router.push("/about");
    },
    goHomeRec() {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/homerec/${this.pages.channel}/${this.pages.subChannel}`
        );
      } else {
        this.$router.push(`/homerec`);
      }
    },
    showForm() {
      this.showModal = true;
    },
    goPage(pagetype) {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/middle/${pagetype}/${this.pages.channel}/${this.pages.subChannel}?code=${this.pageCode}`
        );
      } else {
        this.$router.push(`/rec/middle/${pagetype}?code=${this.pageCode}`);
      }
    },
    // 埋点
    buryingPage(type) {
      this.collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        eventType: type,
        uuid: this.UUID,
        localUrl: location.href,
      };
      collectPointApi(this.collectPointParams)
        .then((res) => {
          console.log(res, "埋点成功");
        })
        .catch((err) => {
          console.log(err, "买点失败");
        });
    },
    telClick() {
      this.buryingPage(3);
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex {
  display: flex;
  align-items: center;
}
.box-wrap /deep/ {
  background: #f7f7f7;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: calc(1.46rem + env(safe-area-inset-bottom));
  overflow-y: auto;
  .banner-box {
    position: relative;
    height: 5.2rem;
    .my-swiper {
      .swipe-img {
        width: 100%;
        height: 5.2rem;
      }
    }
    .swipe-indicators {
      position: absolute;
      bottom: 0.4rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      .indicator-item {
        width: 0.48rem;
        height: 0.08rem;
        background: #fff;
        border-radius: 0.03rem;
        opacity: 0.2;
        margin-right: 0.12rem;
        box-shadow: 1px -0.02rem 0.04rem 0px rgba(0, 0, 0, 0.1);
      }
      .indicator-item :last-of-type {
        margin-right: 0;
      }
      .indicator-item.indicatorActive {
        background: #fff;
        opacity: 1;
      }
    }
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.72rem;
      height: 0.72rem;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .top-box {
    position: relative;
    padding: 0.24rem;
    border-radius: 0.2rem 0.2rem 0 0;
    padding-top: 0.3rem;
    background-color: #fff;
    margin-top: -0.24rem;
    padding-bottom: 0.5rem;
    z-index: 2;
    .top-title {
      font-size: 0.42rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.42rem;
    }
    .tags {
      display: flex;
      align-items: center;
      padding-top: 0.24rem;
      .tag-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.2rem;
        height: 0.4rem;
        background: #fff2e4;
        border-radius: 4px;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff9d20;
        margin-right: 0.16rem;
        margin-bottom: 0.16rem;
      }
    }
    .info-item {
      .info-list {
        display: flex;
        padding-top: 0.12rem;
        .info-label {
          font-size: 0.3rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
          flex: none;
        }
        .info-value {
          p {
            display: flex;
            align-items: baseline;
            color: #951e15;
            span {
              font-size: 0.2rem;
              line-height: 0.28rem;
              font-weight: 500;
            }
            b {
              font-size: 0.4rem;
              font-weight: bold;
              line-height: 0.28rem;
            }
          }
          h4 {
            a {
              display: flex;
              align-items: center;
              font-size: 0.4rem;
              font-family: Alibaba-PuHuiTi-B, Alibaba-PuHuiTi;
              font-weight: bold;
              color: #951e15;
              line-height: 0.42rem;
              span {
                border-bottom: 1px solid #951e15;
              }
            }
            img {
              width: 0.4rem;
              padding-left: 0.1rem;
            }
          }
          h5 {
            font-size: 0.3rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 0.42rem;
          }
        }
      }
    }
    .top-btn-item {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-top: 0.24rem;
      .btn-left {
        width: 5.3rem;
      }

      .btn-icon {
        width: 3.2rem;
        height: 0.58rem;
        margin: 0 auto;
        background: url("~@/assets/img/details/btn-icon.png") no-repeat center
          top;
        background-size: 100%;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #951e15;
        text-align: center;
        line-height: 1;
        padding-top: 0.12rem;
        box-sizing: border-box;
      }
      .btn-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 0.8rem;
        background: #ff9d20;
        box-shadow: 0px 0.04rem 0.1rem 0px rgba(172, 118, 51, 0.35);
        border-radius: 0.5rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #ffffff;
      }
      .btn-right {
        .code-img {
          display: block;
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
    .note-item {
      width: 5.3rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.34rem;
      text-align: center;
      padding-top: 0.12rem;
      span {
        color: #951e15;
      }
    }
  }
  .fl-box {
    .fl-title {
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 0.42rem;
      padding-top: 0.52rem;
      text-align: center;
    }
    .fl-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 0.3rem;
      .fl-list {
        width: 3.42rem;
        height: 1.56rem;
        display: flex;
        align-items: center;
        background-image: url(~@/assets/img/details/fl-list-3.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        &:nth-child(1) {
          background-image: url(~@/assets/img/details/fl-list-1.png);
        }
        &:nth-child(2) {
          background-image: url(~@/assets/img/details/fl-list-2.png);
        }
        .list-left {
          width: 2.5rem;
          text-align: center;
          .left-top {
            display: flex;
            align-items: baseline;
            justify-content: center;
            color: #9f5f37;
            span {
              font-size: 0.24rem;
              font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
              font-weight: 500;
              color: #9f5f37;
              line-height: 0.34rem;
            }
            p {
              font-size: 0.42rem;
              font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
              font-weight: 500;
              color: #9f5f37;
              font-weight: bold;
            }
          }
          .left-bottom {
            padding-top: 0.04rem;
            font-size: 0.22rem;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #995d36;
            line-height: 0.28rem;
            text-align: center;
            span {
              font-weight: bold;
            }
          }
        }
        .fl-right {
          width: 0;
          flex: 1;
          text-align: center;
          font-size: 0.26rem;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          color: #995d36;
          line-height: 1;
          font-weight: bold;
        }
      }
    }
  }
  .list-box {
    padding: 0.24rem;
    background-color: #fff;
    margin-top: 0.2rem;
    .list-item {
      padding-top: 0.4rem;
      &:first-child {
        padding-top: 0.16rem;
      }
      .iframe-box {
        width: 100%;
        height: 4.2rem;
        padding-top: 0.24rem;
        .amap-container {
          height: 100%;
        }
      }
    }
    .list-title {
      display: flex;
      align-items: center;
      .icon-css {
        width: 0.08rem;
        height: 0.42rem;
        background-color: #ff9d20;
        margin-right: 0.12rem;
      }
      h3 {
        font-size: 0.4rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 0.42rem;
      }
    }
    .list-text {
      padding-top: 0.24rem;
      font-size: 0.32rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.48rem;
    }
    .list-img {
      img {
        display: block;
        width: 100%;
        margin: 0.2rem 0;
      }
    }
  }
  .content-box {
    padding: 0.48rem 0.24rem;
    margin-top: 0.16rem;
    background: #fff;
    .lc-content {
      padding: 0.36rem 0.5rem 0.5rem;
      background: linear-gradient(
        180deg,
        rgba(246, 217, 193, 0.12) 0%,
        rgba(211, 167, 126, 0.12) 100%
      );
      // opacity: 0.12;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title-text {
        font-size: 0.42rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.6rem;
      }
      .lc-img-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 0.36rem;
        .lc-img-wrap {
          display: flex;
          align-items: center;
          flex-direction: column;
          .lc-img-css {
            width: 0.8rem;
            height: 0.8rem;
          }
          p {
            font-size: 0.26rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6c340d;
            line-height: 0.38rem;
            padding-top: 0.09rem;
          }
        }
        .lc-icon-css {
          display: block;
          width: 0.34rem;
          margin-top: 0.3rem;
        }
      }
      .last.lc-img-list {
        justify-content: center;
        .lc-img-icon {
          margin: 0 0.28rem;
        }
      }
    }
  }
  .wt-box {
    padding: 0.24rem;
    padding-top: 0.08rem;
    background-color: #fff;
    .title-css {
      font-size: 0.42rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 0.6rem;
      text-align: center;
    }
    .wt-list {
      padding: 0.24rem;
      .wt-item {
        display: flex;
        padding-bottom: 0.32rem;
        .wt-icon {
          flex: none;
          width: 0.32rem;
          height: 0.32rem;
        }
        .wt-text {
          padding: 0 0.12rem;
          flex: 1;
          width: 0;
          font-size: 0.3rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.42rem;
        }
        .zx-btn {
          @include flex();
          justify-content: center;
          width: 1.62rem;
          height: 0.48rem;
          flex: none;
          border-radius: 0.24rem;
          border: 1px solid #ff9d20;
          .zx-icon {
            width: 0.26rem;
            height: 0.22rem;
          }
          span {
            padding-left: 0.1rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff9d20;
          }
        }
      }
    }
  }
  .b-a-box {
    padding: 0.24rem 0;
    background: #f7f7f7;
    text-align: center;
    p {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c1c1c1;
      line-height: 0.36rem;
      span {
        color: #ff9d20;
        text-decoration: underline;
      }
    }
  }
  .kf-box {
    position: fixed;
    right: 0;
    position: fixed;
    top: 45vh;
    width: 1.46rem;
    height: 1.56rem;
    background: url("~@/assets/img/homerec/kf-icon.png") no-repeat center top;
    background-size: 1.46rem 1.56rem;
    padding-top: 1rem;
    box-sizing: border-box;
    transform: translateY(-50%);
    z-index: 2;
    .kf-btn {
      width: 1.12rem;
      height: 0.4rem;
      margin: 0 auto;
      background: #ff9d20;
      box-shadow: 0px 0.04rem 0.16rem 0px rgba(172, 118, 51, 0.35);
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.34rem;
    }
  }
  .fixed-box {
    position: fixed;
    left: 50%;
    width: 7.5rem;
    // height: 1.36rem;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.24rem 0.32rem;
    box-shadow: 0px -0.02rem 0.04rem 0px rgba(0, 0, 0, 0.07);
    background: #fff;
    padding-bottom: calc(0.32rem + env(safe-area-inset-bottom));
    z-index: 9;
    .home-btn-css {
      flex: none;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      padding-right: 0.2rem;
      img {
        display: block;
        width: 0.42rem;
        height: 0.42rem;
      }
      span {
        font-size: 0.24rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff9d20;
        line-height: 0.34rem;
        padding-top: 0.06rem;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 0;
      flex: 1;
    }
    .fiexd-btn {
      width: 2.94rem;
      height: 0.8rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        border: 1px solid #951e15;
        img {
          width: 0.44rem;
          height: 0.4rem;
        }
        .btn-item {
          color: #951e15;
          padding-left: 0.12rem;
          p {
            font-size: 0.32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 0.32rem;
          }
          span {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 0.28rem;
          }
        }
      }
      &:last-child {
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        background: #ff9d20;
      }
    }
  }
  .videoModal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 7.5rem;
    height: 100vh;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 1rem;
    .videoModalContent {
      width: 100%;
      height: 5rem;
      .videoCss {
        width: 100%;
        height: 100%;
      }
    }
    .videoModalClose {
      margin-top: 1rem;
      color: #fff;
      font-size: 0.9rem;
    }
  }
}
.form-wrap {
  padding: 0.5rem;
}
</style>